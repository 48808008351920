button {
    background: #676666;
    border: 1px solid #676666;
    border-radius: 0px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: nunito, roboto, proxima-nova, 'proxima nova', sans-serif;
    font-size: 12px;
    font-weight: 800;
    line-height: 16px;
    min-height: 25px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
}

button:active {
    opacity: 0.5;
    color: black;
}

button:hover {
    opacity: 0.5;
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 10px;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #282c34 inset;
    -webkit-text-fill-color: #ffffff;
}
