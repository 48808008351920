.menubar {
  position: fixed;
  left: 20px;
  display: flex;
  top: 0px;
  height: 60px;
  width: calc(100% - 40px);
  background-color: #676666;
  margin-top: 20px;
  border: none;
  border-radius: 2px;
  /* box-shadow: 0px 0px 2px 2px rgb(0, 0, 0); */
  justify-content: space-between;
}

.button-container-menu {
  display: flex;
  /* gap: 5px; */
}

