.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex; 
  flex-direction: column;
  padding: 35px;
  justify-content: center;
  align-items: center;  
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
