.list-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: grey;
	padding: 5px 20px;
	margin-top: 5px;
	width: 100%;
	text-align: left;
	border-radius: 5px;
}
