.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(10, 12, 12);
  border-radius: 0px 0px 10px 10px;
  width: 80vw;
  margin-bottom: 30px;
  }

.top-container {
  gap: 20px;
  padding: 35px;
  display: flex;
  flex-direction: row;
}

.type-container {
  align-self: flex-end;
  font-size: 20px;
}

.description {
  font-size: 18px;
}

.name-type-container {
  display: flex;
  justify-content: space-between;
}

.button-container {
  display: flex;
  padding: 35px;
  justify-content: space-between;
}

.del-button {
  align-self: flex-start;
}

.right-side-buttons {
  display: flex;
  gap: 20px;
}

.avatar-container {
  flex-direction: row;
}

.name-description {
  text-align: left;
  flex-direction: column;
  width: 100%;
}
